<template>
  <v-content>
    <v-container fill-height>
      <v-layout align-center justify-center class="signin_layout">
        <div class="register_wrapper">
            <div class="register_header_wrapper">
              <div class="register_header">
                <img src="@/assets/img/bici/logo.png" width="40"/>
                <div class="register_title">
                  <small>China1 | HCCP</small>
                  <span>{{ title }}</span>
                </div>
              </div>
              <div class="back_button">
                <router-link to="/SignIn"><i class="mdi mdi-arrow-left"></i></router-link>
              </div>
            </div>
            <div class="forgot_text">
              <p>
                Please, enter your email address.<br>You will receive random password.<br>请输入您的电子邮件地址。 您将收到随机密码。
              </p>
            </div>
            <v-form  v-model="valid" ref="form">
              <div class="forgot_box_wrapper">          
                <div class="forgot_box">
                  <div class="input_fields">
                    <v-text-field 
                      v-model="Email"
                      required
                      name="email" 
                      label="Email" 
                      type="text"
                      :rules="emailRules"
                    ></v-text-field>
                    <v-text-field 
                      v-model="otpCode"   
                      required
                      name="otpCode"
                      label="Otp Code"
                      type="text"
                      :rules="otpRules"
                      maxlength="6"
                    ></v-text-field>
                  </div>
                </div>
                <div class="button_wrapper">
                  <v-btn color="primary" @click="correct">ENTER</v-btn>
                </div>
              </div>
            </v-form>

            <div class="message" :class="[messagered ? 'messagered' : 'messagegreen']">
              <div>{{messageEN}}</div>
              <div>{{messageCH}}</div>
            </div>
          </div>
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>
import '@/assets/stylus/ui/component/_forgot.styl'
import { ApplicationService }  from "@/services"

export default {
  name: 'Forgot',
  mixins: [
    
  ],
  data: () => ({
    messagered:'',
    Email: '',
    messageEN: '',
    messageCH: '',
    otpCode: '999999',
    title: 'Forgot Password',
    selectedItem: {},
    forgotReq: {},
    requestApplied: false,
    accountService: null,
    applicationService: null,
    valid: false,
    emailRules: [
      v => !!v || 'Required',
      v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail is not valid'
    ],
    otpRules: [
      v => !!v || 'OTP is required', // 필수 입력 검사
      v => /^\d{6}$/.test(v) || 'OTP must be exactly 6 digits', // 6자리 숫자 검사
    ],

  }),
  watch:{
    Email(v){
      if (v.length == 0) this.messageEN = '' 
      if (v.length == 0) this.messageCH = '' 
    }
  },
  created() {
    this.applicationService = new ApplicationService()
  },
  methods: {
  
    correct() {
      this.$refs.form.validate()

      if(!this.valid) return
      this.selectedItem.email = this.Email
      this.selectedItem.userId = this.Email
      this.selectedItem.otpCode = this.otpCode  

        this.applicationService.uniqMember(this.selectedItem.userId).then(res => {
        
        if (res == 1) {
          this.forgotReq = {
            userId: this.Email,
            otpCode: this.otpCode 
          }
          this.applicationService.forgotMember(this.forgotReq).then(res => {
            if (!res.error) {
              this.valid = true
              this.messageEN = 'An email has been sent to your email address. Follow directions in the email to reset your password.'
              this.messageCH = '重置密码的邮件已发送到您的邮箱。请按照邮件中的指示进行操作。'
              this.messagered = false
            } else {
              this.valid = false
              this.messageEN = 'An error occurred while resetting your password.'
              this.messageCH = ''
              this.messagered = true
            }
            
          }).catch(err => {
            this.valid = false
            this.messageEN = 'An error occurred while resetting your password.'
            this.messageCH = ''
            this.messagered = true
          })

        } else if (res < 1){
          this.valid = true
          this.messageEN = 'An email has been sent to your email address. Follow directions in the email to reset your password.'
          this.messageCH = '重置密码的邮件已发送到您的邮箱。请按照邮件中的指示进行操作。'
          this.messagered = false
        }
      })


    },

  }
}
</script>